<div class="svi-layout">
  <svi-header></svi-header>
  <div class="content">
    <router-outlet></router-outlet>
    <div class="sponsors">
      <div class="content partner-logo__container">
        <a href="https://www.smh.com.au/" target="_blank" class="partner-logo smh"></a>
        <a href="http://www.anu.edu.au/" target="_blank" class="partner-logo anu"></a>
        <a href="https://www.theage.com.au/" target="_blank" class="partner-logo age"></a>
      </div>
    </div>
  </div>
</div>

<svi-footer></svi-footer>
