import { Component, Input, OnChanges, ViewChild, ElementRef } from '@angular/core'
import { Question, Answer } from '@smartvote/common'
@Component({
  selector: 'svi-questionnaire-question',
  templateUrl: 'question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionnaireQuestionComponent implements OnChanges {
  @Input() question: Question
  @Input() questionIndex: number
  @ViewChild('questionText') questionTextElement: ElementRef

  infoTextExpanded: boolean

  constructor() {}

  // TODO(JLO, 14.08.18): dirty hack to force safari to update content.
  ngOnChanges() {
    // this.infoTextExpanded = this.question.type === 'Standard5Question'
    this.questionTextElement.nativeElement.style.opacity = 0.9
    setTimeout(() => {
      this.questionTextElement.nativeElement.style.opacity = 1
    }, 10)
  }
}
