<svi-navbar></svi-navbar>
<svi-page-title class="d-none d-sm-block" i18n>Welcome to smartwielen</svi-page-title>
<div class="home-page">
  <section class="head">
    <div class="content hero">
      <h1 i18n>Luxembourg General Election</h1>
      <span class="subtitle" i18n>Find candidates & parties that share your opinions!</span>
      <button class="button button-start" (click)="startQuestionnaire()">
        <ng-container i18n>Start now</ng-container>
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
    <div *ngIf="recommendationCount >= 5000" class="sticker">
      <div class="content">
        <span class="text-small" i18n>Already</span>
        <span class="voter-count">{{ recommendationCountLabel }}</span>
        <span class="text-small" i18n>times used</span>
      </div>
    </div>
  </section>

  <section class="white">
    <div class="content">
      <h2 i18n>How smartwielen works</h2>
      <div class="how-sw-works__container">
        <div class="how-sw-works">
          <div class="how-sw-works__icon candidates"></div>
          <div class="how-sw-works__title">
            1.
            <ng-container i18n>Candidates & Parties</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n>Candidates & parties answer 40 questions on a wide range of current policy issues.</ng-container>
          </div>
        </div>
        <div class="how-sw-works">
          <div class="how-sw-works__icon voters"></div>
          <div class="how-sw-works__title">
            2.
            <ng-container i18n>Voters</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n>Voters answer the same questions on the smartwielen website.</ng-container>
          </div>
        </div>
        <div class="how-sw-works">
          <div class="how-sw-works__icon matching"></div>
          <div class="how-sw-works__title">
            3.
            <ng-container i18n>Matching</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n>Voters receive a ranked list of candidates & parties that best match their political profile.</ng-container>
          </div>
        </div>
      </div>
      <div class="action">
        <button routerLink="/faq" class="button">
          <i class="fa fa-question-circle"></i>
          <ng-container i18n>FAQ</ng-container>
        </button>
      </div>
    </div>
  </section>

  <section class="light about">
    <div class="content half">
      <h2 i18n>Who are we?</h2>
      <p>
        <ng-container i18n>
          smartwielen is a project of the University of Luxembourg in collaboration with the Zentrum fir politesch Bildung. The smartwielen
          platform is an adapted version of smartvote, a so-called VAA (Voting Advice Application) that was first developed
          in Switzerland in 2003 and is operated by the politically neutral, non-profit organization Politools.
        </ng-container>
        <a routerLink="/about-us">
          <ng-container>&nbsp;</ng-container>
          <ng-container i18n>About Us</ng-container>&nbsp;
          <i class="fa fa-angle-right"></i>
        </a>
      </p>
    </div>
    <div class="content half current-election">
      <h2 i18n="@@home.electionLinkTitle">smartvote australia 2020
      </h2>
      <p i18n="@@home.electionLinkDescription">Find candidates & parties that share your opinions!
      </p><a href="https://australia.smartvote.org" target="_blank" i18n="@@home.electionLinkText">Go to smartvote
        australia 2020
        &nbsp; <i class="fa fa-angle-right"></i></a>
    </div>
  </section>

  <section class="light"></section>
</div>