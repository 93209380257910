import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core'
import { Election, District, DistrictGroup } from '@smartvote/common'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Subscription } from 'rxjs'
import { TrackingService } from '../../core/tracking.service'
import { HttpClient } from '@angular/common/http'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

export class FilterGroupState {
  election = ''
  district = ''
  districtGroup = ''
  zipCode = ''
  responderType: 'Candidate' | 'Party' = 'Candidate'
}

export function stateIsValid(s) {
  return s.election && s.district
}

export function stateIsEqual(a: FilterGroupState, b: FilterGroupState) {
  return (
    a.election === b.election &&
    a.district === b.district &&
    a.districtGroup === b.districtGroup &&
    a.responderType === b.responderType
  )
}

interface ZipDistrict {
  zip_code: string
  district_id: string
}

@Component({
  selector: 'svi-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnDestroy {
  zipDistricts: ZipDistrict[]
  @Input()
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.form.disable({ emitEvent: false })
    } else {
      this.form.enable({ emitEvent: false })
    }
  }
  get disabled() {
    return this._disabled
  }
  private _disabled = false
  /* Input `elections` */
  @Input()
  set elections(value: Election[]) {
    if (value) {
      this._elections = value
    } else {
      this._elections = []
    }
    this._setState(new FilterGroupState())
  }
  get elections(): Election[] {
    return this._elections
  }

  /* Input `state` */
  @Input()
  set state(value: FilterGroupState) {
    this._setState(value)
  }
  get state() {
    return this._state
  }
  /* Output `stateChanged` */
  @Output() stateChanged = new EventEmitter<FilterGroupState>()

  get districts(): District[] {
    if (!this.election) {
      return []
    }
    if (!this.election.districtGroups.length) {
      return this.election.districts
    }
    if (this.state.zipCode) {
      return this.getDistrictsByZip(this.state.zipCode, this.election)
    }
    return this.election.districts.filter((d: District) => {
      return d.groupId === this.state.districtGroup
    })
  }
  get districtGroups(): DistrictGroup[] {
    if (!this.election) {
      return []
    }
    return this.election.districtGroups
  }
  get election(): Election | undefined {
    if (!this._state.election) {
      return
    }
    return this.elections.find(e => e.id === this.state.election)
  }
  form: FormGroup
  private _elections: Election[]
  private _state: FilterGroupState
  private _sub: Subscription

  constructor(fb: FormBuilder, private trackingService: TrackingService, private http: HttpClient) {
    this._state = new FilterGroupState()
    this.form = fb.group(this._state)
    this._sub = this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(state => {
        this._state = { ...this.state, ...state }

        if (this.elections.length === 1) {
          // auto select single election
          this._state.election = this.elections[0].id
        }
        if (this.districtGroups.length === 1) {
          // auto select single districtGroup
          this._state.districtGroup = this.districtGroups[0].id
        }
        if (this.districts.length === 1) {
          // auto select single district
          this._state.district = this.districts[0].id
        }

        this.stateChanged.emit(this._state)
      })
    this.http
      .get('/assets/json/zip-districts.json')
      .toPromise()
      .then(data => {
        this.zipDistricts = data as ZipDistrict[]
      })
  }

  ngOnDestroy() {
    this._sub.unsubscribe()
  }

  onResponderTypeChange(responderType) {
    this.trackingService.trackEvent('Matching', 'selectResponderType', responderType)
    this.form.patchValue({ responderType })
  }

  trackSelectDistrict(districtId: string) {
    this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId)
  }
  trackSelectDistrictGroup(districtGroupId: string) {
    this.trackingService.trackEvent(
      'Matching',
      'selectDistrictGroup',
      'DistrictGroup' + districtGroupId
    )
  }

  getDistrictsByZip(zip: string, election) {
    const districts = this.zipDistricts.filter(zd => zd.zip_code === zip).map(zd => {
      return election.districts.find(d => d.id === zd.district_id)
    })
    if (districts.length) {
      // set districtGroup
      this.form.controls.districtGroup.patchValue(districts[0].groupId, {
        emitEvent: false
      })
    }
    return districts
  }

  private _setState(state: FilterGroupState = new FilterGroupState()) {
    this._state = state

    if (this.elections.length > 1) {
      this.form.controls.election.enable({ emitEvent: false })
    } else {
      this.form.controls.election.disable({ emitEvent: false })
    }

    if (this.districtGroups.length > 1) {
      this.form.controls.districtGroup.enable({ emitEvent: false })
    } else {
      this.form.controls.districtGroup.disable({ emitEvent: false })
    }

    if (this.districts.length > 1) {
      this.form.controls.district.enable({ emitEvent: false })
    } else {
      this.form.controls.district.disable({ emitEvent: false })
    }

    this.form.patchValue(this._state, { emitEvent: false })
  }
}
