import { Component, OnInit, Input } from '@angular/core'

export type AnswerOptionSelectionType = 'voter' | 'candidate'
export const ANSWER_OPTION_VALUES = [0, 25, 75, 100]

@Component({
  selector: 'svi-answer-option-item',
  templateUrl: './answer-option-item.component.html',
  styleUrls: ['./answer-option-item.component.scss']
})
export class AnswerOptionItemComponent implements OnInit {
  @Input() selections: AnswerOptionSelectionType[]
  @Input() color: string
  @Input() label: string
  @Input() showLabel: boolean

  constructor() {}

  isSelected(type: AnswerOptionSelectionType): boolean {
    return this.selections && this.selections.indexOf(type) >= 0
  }

  ngOnInit() {}
}
