<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Frequently Asked Questions (FAQ)
</svi-page-title>
<svi-page-content>
  <h3 i18n>What is smartwielen?</h3>
  <p i18n>
    smartvote Australia is an innovative online platform, which matches voters to candidates and
    parties (running in the 2019 Australian federal elections) that share their policy positions.
    smartvote Australia gives users access to first-hand information collected from political
    parties and candidates. It allows users not only to reflect on their own political positions,
    but also to learn about the views and political programmes of the parties and candidates.
    Furthermore, it allows them to find out which of the parties and candidates correspond best to
    their own political profile. The questionnaire includes 35 questions that cover a broad spectrum
    of political issues. The questions are answered by candidates and parties as well as users. The
    results are based on the 35 questions and show differences and similarities between the
    positions of the political parties and/or candidates and the users of the site. It is important
    to note, that the goal of smartvote Australia is not to encourage users to vote in a certain
    way, but rather to invite them to seek out more information. To learn more about how smartvote
    Australia works, see the <a routerLink="/methodology">methodology</a>.
  </p>

  <h3 i18n>How was the questionnaire developed?</h3>
  <p i18n>The main criteria for the selection of questions are:</p>
  <ul>
    <li i18n>they are concerned with the current political issues in Luxembourg and Europe;</li>
    <li i18n>they do not favor one or more political party(s);</li>
    <li i18n>they do not allow for multiple interpretations;</li>
    <li i18n>Sie haben einen allgemeinen oder spezifischen Charakter (eine Mischung beider Arten wird angestrebt); Platzhalter</li>
    <li i18n>they deal with only one subject;</li>
    <li i18n>
      they fit the design of the smartspider. For more information, see the
      <a routerLink="/methodology">methodology</a>.
    </li>
  </ul>

  <h3 i18n>How are the candidates’ and parties’ positions determined?</h3>
  <p i18n>The candidates and parties answer the smartwielen questionnaire, which includes 43 questions on a wide range of
    current policy issues, some weeks before the election. By doing so they generate their political profile at smartwielen.lu.</p>

  <h3 i18n>How are the results calculated?</h3>
  <p i18n>
    On the smartvote Australia website users answer the exact same questionnaire that candidates and
    parties have already answered. smartvote Australia compares their answers with those of the
    candidates or parties by calculating the euclidean distance (the straight-line distance between
    two-points in a multidimensional space). As a result, smartvote Australia presents to each voter
    a specific ranking of all participating candidates or parties in their electorate. Those
    candidates or parties with the best matching are ranked at the top of the list. The more
    questions a user answers, the more precise their matching will be. For more information, see the
    <a routerLink="/methodology">methodology</a>.
  </p>

  <h3 i18n>How should I interpret the matching score?</h3>
  <p i18n>The results are based on a geometric matching. Therefore, the value cannot be considered directly as a proportion
    of the questions in which a voter is absolutely in agreement with the answers of a candidate. Therefore, a match of 70%
    does not mean that a candidate has given exactly the same answer as the voter for exactly 70% of the questions.</p>

  <h3 i18n>How does weighting answers effect my results?</h3>
  <p i18n>
    Users have the opportunity to weight their answers, which will be considered when calculating
    their results. This allows users to indicate which topics are more or less important to them. If
    a user gives a question a plus sign, then the answer counts twice in the calculation of the
    matching results (+ = 2). If a user gives a question a minus sign, it counts as if the answer
    were half as important (- = 0.5). For more information, see the
    <a routerLink="/methodology">methodology</a>.
  </p>

  <h3 i18n>I filled out smartwielen a few weeks ago, how can I find my old results?</h3>
  <p i18n>The answers that a user gives are saved on the computer's local storage. As long as the user does not delete the
    local storage, the user can revise their results at any time.</p>

  <h3 i18n>How can I interpret the smartspider? How is the smartspider calculated?</h3>
  <p i18n>
    The smartspider is a graphical visualisation of the political profiles of the candidates/parties
    and user based on their answers to the questionnaire. Each of the six axes represents a
    formulated goal in a policy area (e.g. Environmental Protection) based on 2-4 questions from the
    questionnaire. Some questions have been assigned to multiple axes and some questions have not
    been assigned to any axes. For each axis a value of between 100 and 0 points can be reached. A
    value of 100, at the outside edge of the spider, represents strong agreement with the formulated
    goal of the corresponding axis. A value of 0, at the middle of the spider, means that there is
    no agreement with the formulated goal. The more similar the political profiles of a
    candidate/party and user are, the more their smartspiders will overlap. For more information on
    the smartspider and which questions are assigned with which axes, see the
    <a routerLink="/methodology">methodology</a>.
  </p>

  <h3 i18n>How can I interpret the smartmap? How is the smartmap calculated?</h3>
  <p i18n>
    The smartmap is a graphical visualisation of the political profiles of the candidates/parties
    and user based on their answers to the questionnaire. Each of the two axes represents ca. 20
    questions from the questionnaire. Some questions have been assigned to both axes and some
    questions have not been assigned to either. The more similar the political profiles of a
    candidate/party and user are, the closer the points marking their positions on the smartmap will
    be. The horizontal axis represents the classic economic left–right dimension, which focuses on
    the role of government intervention in the economy. The vertical axis represents social
    liberal-conservative dimension, which focuses on the role of government in holding up
    traditional values and institutions. For more information on the smartmap and which questions
    are assigned with which axes, see the <a routerLink="/methodology">methodology</a>.
  </p>

  <h3 i18n>Why are some candidates and parties not included in the platform? Why are there no answers for some parties and
    candidates?</h3>
  <p i18n>We contacted all parties and candidates (via the parties) ahead of the launching of the tool and asked if they
    were interested in participating. All parties and candidates that agreed are included in the website. However, not all
    parties and candidates have completed and confirmed the questionnaire.</p>

  <h3 i18n>What happens to my answers in the user survey?</h3>
  <p i18n>
    The answers given in the user survey are anonymous and will not affect your results. They will
    be used at the aggregated level by the Australian National University for research purposes. For
    more information, please contact us via
    <a href="mailto:smartvote@anu.edu.au">smartvote@anu.edu.au</a>.
  </p>

  <h3 i18n>Is smartwielen associated with any specific political parties, candidates, special interests, etc.?</h3>
  <p i18n>No, all organisations involved in the development and operation of smartwielen (e.g., the University of Luxembourg,
    Zentrum fir politesch Bildung and smartvote/Politools) are non-partisan and non-profit organizations.</p>
</svi-page-content>