import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkAccordionModule } from '@angular/cdk/accordion'

import { SharedModule } from '../shared/shared.module'

import { QuestionnaireNavigationComponent } from './navigation/questionnare-navigation.component'
import { QuestionnaireQuestionComponent } from './question/question.component'
import { QuestionnaireActionsComponent } from './actions/actions.component'
import { QuestionnaireAnswerComponent } from './answer/answer.component'
import { QuestionnairePage } from './questionnaire.page'

const COMPONENTS = [
  QuestionnaireNavigationComponent,
  QuestionnaireQuestionComponent,
  QuestionnaireActionsComponent,
  QuestionnaireAnswerComponent,
  QuestionnairePage
]

@NgModule({
  imports: [CommonModule, SharedModule, CdkAccordionModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class QuestionnaireModule {}
