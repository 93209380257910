import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SmartmapModule } from '@smartvote/components'
import { CdkAccordionModule } from '@angular/cdk/accordion'

import { SharedModule } from '../shared/shared.module'

import { MatchingPage } from './matching.page'
import { MatchingSharePage } from './matching-share.page'

import { FilterGroupComponent } from './filter-group/filter-group.component'

const COMPONENTS = [MatchingPage, MatchingSharePage, FilterGroupComponent]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SmartmapModule,
    CdkAccordionModule,
    RouterModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class MatchingModule {}
