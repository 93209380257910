var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { TemplateRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LastRouteService } from './last-route.service';
import { Apollo } from 'apollo-angular';
import { Election, Recommendation, Candidate, Party, Matching, isCandidate } from '@smartvote/common';
import { AnswerService } from '../core/answer.service';
import { VoterIdService } from '../core/voter-id.service';
import { getSmartmapPositions, getSmartmapLegendItems } from './matching.page';
import { OfficialAnswersService } from '../core/official-answers.service';
var _a = require('graphql-tag/loader!./matching.page.graphql'), GetRecommendation = _a.GetRecommendation, GetElections = _a.GetElections;
var MatchingSharePage = /** @class */ (function () {
    function MatchingSharePage(apollo, router, route, answerService, officialAnswersService, voterIdService, platformId, localeId, lastRouteSrv) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this.answerService = answerService;
        this.officialAnswersService = officialAnswersService;
        this.voterIdService = voterIdService;
        this.platformId = platformId;
        this.localeId = localeId;
        this.lastRouteSrv = lastRouteSrv;
        this.tabIndex = 0;
        this.resultSummary = {
            district: '',
            nofSeats: '',
            nofCandidates: '',
            districtType: ''
            // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
        };
        this.loading = true;
        this.infoTextExpanded = false;
        this.hasOfficialAnswers = this.officialAnswersService.hasOfficialAnswers;
        this.elections = this._getElections(); // TODO: Fix types
        this.recommendation = combineLatest(this.route.queryParams, this.elections).pipe(switchMap(function (_a) {
            var params = _a[0], elections = _a[1];
            _this._elections = elections;
            _this.tabIndex = parseInt(params['tab'], 10) || 0;
            var recommendationId = params['rid'];
            _this.loading = true;
            if (_this._doGetRecommendation(recommendationId)) {
                return _this._getRecommendation(recommendationId, 0, -1);
            }
            else if (_this._recommendation) {
                return of(_this._recommendation);
            }
            else {
                return of(null);
            }
        }), tap(function (recommendation) {
            _this.loading = false;
            if (!recommendation) {
                return;
            }
            recommendation.positions = getSmartmapPositions(recommendation).concat(__assign({ id: 'me', color: 'red', label: _this.getTranslatedMessage('my-position') }, recommendation.voter.smartmapPosition));
            recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation);
            recommendation.matchings = recommendation.matchings.map(function (matching) { return (__assign({}, matching, _this._getListItemLabels(matching))); });
            if (!_this._recommendation || _this._recommendation.id !== recommendation.id) {
                // Keep last state before emiting to avoid infite loop
                _this._recommendation = recommendation;
                _this._updateQueryParams({ rid: recommendation.id });
                _this._updateResultSummary(_this._elections, recommendation);
                _this._updateNofSeats(_this._elections, recommendation);
            }
            _this.someHaveOfficialPartyAnswers = recommendation.matchings.some(function (m) {
                return _this.hasOfficialAnswers(m.responder);
            });
            _this.isCandidateRecommendation = recommendation.matchings.some(function (m) {
                return isCandidate(m.responder);
            });
        }));
    }
    MatchingSharePage.prototype.ngAfterViewInit = function () {
        this.translations = this.translationTemplate
            .createEmbeddedView({})
            .rootNodes.reduce(function (prev, curr) {
            var _a;
            return (__assign({}, prev, (_a = {}, _a[curr.id] = curr.textContent, _a)));
        }, {});
    };
    MatchingSharePage.prototype.navigateToMethodology = function () {
        this.router.navigate(['/methodology'], { fragment: 'smartmap' });
    };
    MatchingSharePage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    MatchingSharePage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    MatchingSharePage.prototype._updateResultSummary = function (elections, recommendation) {
        if (!(elections && recommendation)) {
            return;
        }
        var election = elections.find(function (e) { return e.id === recommendation.options.electionId; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === recommendation.options.districtId; });
        this.resultSummary = {
            district: district.name,
            nofSeats: "" + district.seats,
            nofCandidates: "" + recommendation.matchings.length,
            districtType: "" + district.type
        };
    };
    MatchingSharePage.prototype._updateNofSeats = function (elections, recommendation) {
        if (!elections) {
            return;
        }
        var election = elections.find(function (e) { return e.id === recommendation.options.electionId; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === recommendation.options.districtId; });
        this.nofSeats = district.seats;
    };
    MatchingSharePage.prototype._getListItemLabels = function (matching) {
        var title = '';
        var description = '';
        var matchingValue = matching.matchValue;
        if (matching.responderType === 'Candidate') {
            var candidate = matching.responder;
            if (candidate) {
                title = candidate.firstname + " " + candidate.lastname;
                description = candidate.party ? candidate.party.name : '';
                if (candidate.nofAnswers === 0) {
                    description += " (" + this.getTranslatedMessage('no-answers') + ")";
                    matchingValue = null;
                }
            }
            else {
                title = this.getTranslatedMessage('no-information');
            }
        }
        else if (matching.responderType === 'Party') {
            var party = matching.responder;
            if (party) {
                title = party.name;
            }
            else {
                title = this.getTranslatedMessage('no-information');
            }
            if (party.nofAnswers === 0) {
                description = " (" + this.getTranslatedMessage('no-answers') + ")";
                matchingValue = null;
            }
        }
        return { title: title, description: description, matchingValue: matchingValue };
    };
    MatchingSharePage.prototype._getElections = function () {
        return this.apollo
            .query({
            query: GetElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    MatchingSharePage.prototype._getRecommendation = function (recommendationId, offset, limit) {
        return this.apollo
            .query({
            query: GetRecommendation,
            variables: {
                recommendationId: recommendationId,
                offset: offset,
                limit: limit
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (__assign({}, data.recommendation));
        }));
    };
    MatchingSharePage.prototype._doGetRecommendation = function (rid) {
        return rid && !this._recommendation;
    };
    MatchingSharePage.prototype.onMatchingSelected = function (matching) {
        if (matching.responder) {
            if (matching.responderType === 'Candidate') {
                this.router.navigate(['profile', 'candidate', matching.responder.id], {
                    relativeTo: this.route.parent,
                    queryParamsHandling: 'preserve'
                });
            }
            else if (matching.responderType === 'Party') {
                this.router.navigate(['profile', 'party', matching.responder.id], {
                    relativeTo: this.route.parent,
                    queryParamsHandling: 'preserve'
                });
            }
        }
    };
    MatchingSharePage.prototype.navigateToHome = function () {
        this.router.navigate(['home']);
    };
    MatchingSharePage.prototype.onSmartmapItemSelect = function (itemId) {
        var selectedMatching = this._recommendation.matchings.filter(function (matching) { return matching.responderId === itemId; });
        if (selectedMatching.length === 1) {
            this.onMatchingSelected(selectedMatching[0]);
        }
    };
    MatchingSharePage.prototype.getTranslatedMessage = function (msg) {
        return this.translations[msg];
    };
    return MatchingSharePage;
}());
export { MatchingSharePage };
