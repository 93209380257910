<svi-navbar></svi-navbar>
<svi-page-title>
  <ng-container i18n>Question</ng-container>
  {{ progress }}
</svi-page-title>

<div *ngIf="(questions | async) as questions; else loading">
  <svi-questionnaire-navigation
    [questions]="questions"
    [userAnswers]="userAnswers"
    [questionIndex]="questionIndex"
    (navigate)="jumpToQuestion($event)"
  >
  </svi-questionnaire-navigation>

  <svi-questionnaire-question
    [question]="questions[questionIndex]"
    [questionIndex]="questionIndex"
  ></svi-questionnaire-question>

  <div class="svi-questionnaire-answer-actions">
    <svi-questionnaire-answer
      [questionType]="questions[questionIndex].type"
      [number]="questionIndex + 1"
      [userAnswer]="userAnswers[questionIndex]"
      (answerChanged)="changeAnswer($event)"
    >
    </svi-questionnaire-answer>
    <svi-questionnaire-actions
      [questions]="questions"
      [questionIndex]="questionIndex"
      (navigate)="navigate($event)"
      (done)="showResults()"
      [doneDisabled]="!hasAnswers"
      doneLabel="See results"
      i18n-doneLabel
    ></svi-questionnaire-actions>
  </div>
</div>

<ng-template #loading>
  <svi-page-content>
    <svi-loading></svi-loading>
  </svi-page-content>
</ng-template>
