import { Injectable, Inject } from '@angular/core'
import * as uuid from 'uuid'
import { BrowserStorage } from './tokens'

@Injectable()
export class VoterIdService {
  constructor(@Inject(BrowserStorage) private browserStorage: Storage) {
    const voterId = browserStorage.getItem('smv-voterId')
    if (!voterId) {
      this.browserStorage.setItem('smv-voterId', uuid.v1())
    }
  }

  getVoterId() {
    return this.browserStorage.getItem('smv-voterId')
  }
}
