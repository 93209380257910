var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { TemplateRef, AfterViewInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
var GetDatabaseElections = require('graphql-tag/loader!./database.page.graphql').GetDatabaseElections;
import { FilterGroupState } from './database-filter-group/database-filter-group.component';
var DatabasePage = /** @class */ (function () {
    function DatabasePage(apollo, router, route, browserStorage) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this.browserStorage = browserStorage;
        this.parties = [];
        this.filterGroupStateChanges = new BehaviorSubject(new FilterGroupState());
        this.loadingdata = false;
        this.showList = false;
        this.tabIndex = 0;
        this.elections = this.getElections();
        this.elections.subscribe(function (elections) {
            _this.parties = elections[0].parties;
        });
        this.candidates = combineLatest(this.elections, this.filterGroupStateChanges).pipe(map(function (_a) {
            var elections = _a[0], filterGroupState = _a[1];
            _this.loadingdata = false;
            var election = elections.find(function (e) { return e.id === filterGroupState.election; });
            return _this.filterCandidates(election.candidates, filterGroupState).map(function (candidate) {
                return __assign({}, candidate, { description: _this.getCandidateDescription(candidate) });
            });
        }));
        this.route.queryParamMap.subscribe(function (queryParamMap) {
            _this.tabIndex = parseInt(queryParamMap.get('tab'), 10) || 0;
        });
        var savedState = this.browserStorage.getItem('profiles-filter-group');
        if (savedState) {
            this.onSearch(JSON.parse(savedState));
        }
    }
    DatabasePage.prototype.ngAfterViewInit = function () {
        this.translations = this.translationTemplate
            .createEmbeddedView({})
            .rootNodes.reduce(function (prev, curr) {
            var _a;
            return (__assign({}, prev, (_a = {}, _a[curr.id] = curr.textContent, _a)));
        }, {});
    };
    DatabasePage.prototype.getElections = function () {
        return this.apollo
            .query({
            query: GetDatabaseElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    DatabasePage.prototype.onSearch = function (state) {
        this.loadingdata = true;
        this.showList = true;
        this.browserStorage.setItem('profiles-filter-group', JSON.stringify(state));
        this.filterGroupStateChanges.next(state);
    };
    DatabasePage.prototype.onReset = function () {
        this.browserStorage.setItem('profiles-filter-group', '');
        this.showList = false;
    };
    DatabasePage.prototype.onTabChanged = function (index) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: index
            },
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    DatabasePage.prototype.navigateToCandidate = function (candidateId, electionId) {
        this.router.navigate(['profiles', 'candidate', candidateId, electionId]);
    };
    DatabasePage.prototype.navigateToParty = function (partyId) {
        this.router.navigate(['profiles', 'party', partyId]);
    };
    DatabasePage.prototype.filterCandidates = function (candidates, filterGroupState) {
        return candidates.filter(function (candidate) {
            if (filterGroupState.districtGroup) {
                if (!(candidate.district.groupId && candidate.district.groupId === filterGroupState.districtGroup)) {
                    return false;
                }
            }
            if (filterGroupState.district) {
                if (!(candidate.district && candidate.district.id === filterGroupState.district)) {
                    return false;
                }
            }
            if (filterGroupState.party) {
                if (!(candidate.party && candidate.party.id === filterGroupState.party)) {
                    return false;
                }
            }
            if (filterGroupState.incumbent !== '') {
                if (!((candidate.incumbent && filterGroupState.incumbent) ||
                    (!candidate.incumbent && !filterGroupState.incumbent))) {
                    return false;
                }
            }
            if (filterGroupState.elected !== '') {
                if (!((candidate.elected && filterGroupState.elected) ||
                    (!candidate.elected && !filterGroupState.elected))) {
                    return false;
                }
            }
            if (filterGroupState.gender) {
                if (!(candidate.gender.toString() === filterGroupState.gender)) {
                    return false;
                }
            }
            if (filterGroupState.answersConfirmed) {
                if (!(
                // TODO (BSC): Adapt candidate type in @smartvote/common
                (candidate.answersConfirmed && filterGroupState.answersConfirmed === 'true') ||
                    (!candidate.answersConfirmed && filterGroupState.answersConfirmed === 'false'))) {
                    return false;
                }
            }
            if (filterGroupState.name) {
                if (!(candidate.firstname.toLowerCase().includes(filterGroupState.name.toLowerCase()) ||
                    candidate.lastname.toLowerCase().includes(filterGroupState.name.toLowerCase()))) {
                    return false;
                }
            }
            return true;
        });
    };
    DatabasePage.prototype.getCandidateDescription = function (candidate) {
        var descriptions = [];
        if (candidate.party) {
            descriptions.push(candidate.party.name);
        }
        if (candidate.incumbent) {
            descriptions.push(this.getTranslatedMessage('incumbent'));
        }
        if (candidate.elected) {
            descriptions.push(this.getTranslatedMessage('elected'));
        }
        if (candidate.yearOfBirth) {
            descriptions.push(candidate.yearOfBirth);
        }
        if (candidate.gender) {
            if (candidate.gender === 1) {
                descriptions.push(this.getTranslatedMessage('female'));
            }
            else if (candidate.gender === 2) {
                descriptions.push(this.getTranslatedMessage('male'));
            }
            else if (candidate.gender === 3) {
                descriptions.push(this.getTranslatedMessage('other'));
            }
        }
        return descriptions.reduce(function (previousValue, currentValue) {
            if (previousValue) {
                return previousValue + " | " + currentValue;
            }
            else {
                return "" + currentValue;
            }
        }, '');
    };
    DatabasePage.prototype.getTranslatedMessage = function (msg) {
        return this.translations[msg];
    };
    return DatabasePage;
}());
export { DatabasePage };
