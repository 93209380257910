import { NgModule, APP_INITIALIZER, Optional } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { LayoutComponent } from './layout/layout.component'
import { environment } from '../../environments/environment'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component'
import { LanguageMenuComponent } from './header/language-menu/language-menu.component'
import { LanguageOverlayComponent } from './header/language-menu/language-overlay/language-overlay.component'

import { SharedModule } from '../shared/shared.module'
import { BrowserStorage } from './tokens'
import { AnswerService } from './answer.service'
import { OfficialAnswersService } from './official-answers.service'
import { VoterIdService } from './voter-id.service'
import { TrackingService } from './tracking.service'
import { IFRAME_SETTINGS, iframeSettingsProvider } from './iframe'
import { TransferState, BrowserTransferStateModule } from '@angular/platform-browser'

const COMPONENTS = [
  LayoutComponent,
  FooterComponent,
  HeaderComponent,
  LanguageMenuComponent,
  LanguageOverlayComponent,
  PolicyLayoverComponent
]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, BrowserTransferStateModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: BrowserStorage,
      useValue: typeof window !== 'undefined' ? window.localStorage : { setItem() {}, getItem() {} }
    },
    {
      provide: IFRAME_SETTINGS,
      useFactory: iframeSettingsProvider,
      deps: [TransferState]
    },
    AnswerService,
    OfficialAnswersService,
    VoterIdService,
    TrackingService
  ],
  entryComponents: [LanguageOverlayComponent]
})
export class CoreModule {}
