/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../matching-value-bar/matching-value-bar.component.ngfactory";
import * as i3 from "../matching-value-bar/matching-value-bar.component";
import * as i4 from "@angular/common";
import * as i5 from "../profile-photo/profile-photo.component.ngfactory";
import * as i6 from "../profile-photo/profile-photo.component";
import * as i7 from "./list-item.component";
var styles_ListItemComponent = [i0.styles];
var RenderType_ListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListItemComponent, data: {} });
export { RenderType_ListItemComponent as RenderType_ListItemComponent };
function View_ListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rank; _ck(_v, 1, 0, currVal_0); }); }
function View_ListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "%"])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.matchingValue, ".1-1", "en")); _ck(_v, 1, 0, currVal_0); }); }
function View_ListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "matching-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svi-matching-value-bar", [["class", "bar"]], null, null, null, i2.View_MatchingValueBarComponent_0, i2.RenderType_MatchingValueBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.MatchingValueBarComponent, [], { value: [0, "value"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListItemComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchingValue; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isPlaceholder; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ListItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "svi-matching-list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svi-profile-photo", [["class", "profile-photo"]], null, null, null, i5.View_ProfilePhotoComponent_0, i5.RenderType_ProfilePhotoComponent)), i1.ɵdid(3, 49152, null, 0, i6.ProfilePhotoComponent, [], { photoUrl: [0, "photoUrl"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "title truncate"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListItemComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListItemComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListItemComponent_3)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-chevron-right list-item-arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photoUrl; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.rank && (_co.rank !== "")); _ck(_v, 8, 0, currVal_1); var currVal_3 = _co.description; _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.matchingValue || (_co.matchingValue === 0)); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 9, 0, currVal_2); }); }
export function View_ListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-list-item", [], null, null, null, View_ListItemComponent_0, RenderType_ListItemComponent)), i1.ɵdid(1, 49152, null, 0, i7.ListItemComponent, [], null, null)], null, null); }
var ListItemComponentNgFactory = i1.ɵccf("svi-list-item", i7.ListItemComponent, View_ListItemComponent_Host_0, { photoUrl: "photoUrl", rank: "rank", title: "title", description: "description", matchingValue: "matchingValue", disabled: "disabled", isPlaceholder: "isPlaceholder" }, {}, []);
export { ListItemComponentNgFactory as ListItemComponentNgFactory };
