import { EventEmitter } from '@angular/core';
import { QuestionType } from '@smartvote/common';
var QuestionnaireAnswerComponent = /** @class */ (function () {
    function QuestionnaireAnswerComponent() {
        this.questionType = QuestionType.StandardQuestion;
        this.responderType = 'Voter';
        this.answerChanged = new EventEmitter();
    }
    QuestionnaireAnswerComponent.prototype.answerChange = function (newValue) {
        this.answerChanged.emit(Object.assign(this.userAnswer, { value: newValue }));
    };
    QuestionnaireAnswerComponent.prototype.weightChange = function (newValue) {
        this.answerChanged.emit(Object.assign(this.userAnswer, { weight: newValue }));
    };
    Object.defineProperty(QuestionnaireAnswerComponent.prototype, "isStandardQuestion", {
        get: function () {
            // return this.questionType === QuestionType.Standard5Question || this.questionType === QuestionType.StandardQuestion
            return this.questionType === QuestionType.StandardQuestion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionnaireAnswerComponent.prototype, "isBudgetQuestion", {
        get: function () {
            // return this.questionType === QuestionType.BudgetQuestion || this.questionType === QuestionType.Budget5Question
            return this.questionType === QuestionType.Standard5Question;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionnaireAnswerComponent.prototype, "has5Options", {
        get: function () {
            // return this.questionType === QuestionType.Budget5Question || this.questionType === QuestionType.Standard5Question
            return this.questionType === QuestionType.Standard5Question;
        },
        enumerable: true,
        configurable: true
    });
    return QuestionnaireAnswerComponent;
}());
export { QuestionnaireAnswerComponent };
