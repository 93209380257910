import { LayoutComponent } from './layout/layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component';
import { LanguageMenuComponent } from './header/language-menu/language-menu.component';
import { LanguageOverlayComponent } from './header/language-menu/language-overlay/language-overlay.component';
import { iframeSettingsProvider } from './iframe';
var COMPONENTS = [
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    LanguageMenuComponent,
    LanguageOverlayComponent,
    PolicyLayoverComponent
];
var ɵ0 = typeof window !== 'undefined' ? window.localStorage : { setItem: function () { }, getItem: function () { } }, ɵ1 = iframeSettingsProvider;
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1 };
