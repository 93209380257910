import { Component, Inject, AfterViewInit, PLATFORM_ID } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { Apollo } from 'apollo-angular'
import { isPlatformBrowser } from '@angular/common'

import { GetElectionPartiesQuery } from '../../__generated__/types'
import { map, tap } from 'rxjs/operators'
import { Party } from '@smartvote/common'
import { Observable } from 'rxjs'
import { BrowserStorage } from '../core/tokens'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { TrackingService } from '../core/tracking.service'

const { GetElectionParties } = require('graphql-tag/loader!./questions-about-you.page.graphql')

export const USER_SURVEY_KEY = 'user-survey'

const DISPLAYED_PARTY_IDS: string[] = ['1', '2', '3', '4', '26']

@Component({
  selector: 'svi-questions-about-you-page',
  templateUrl: 'questions-about-you.page.html',
  styleUrls: ['questions-about-you.page.scss']
})
export class QuestionsAboutYouPage implements AfterViewInit {
  form: FormGroup
  parties: Observable<Party>

  constructor(
    private router: Router,
    private apollo: Apollo,
    private trackingService: TrackingService,
    route: ActivatedRoute,
    @Inject(BrowserStorage) private browserStorage: Storage,
    fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId
  ) {
    const forward = coerceBooleanProperty(route.snapshot.queryParams.fwd)
    if (forward && !!browserStorage.getItem('user-survey-shown')) {
      this.continue({ replaceUrl: true, saveForm: false })
      return
    }
    this.form = fb.group({
      yearOfBirth: '',
      gender: '',
      zip: '',
      nationality: '',
      profession: '',
      education: '',
      enrolledToVote: '',
      otherLanguage: '',
      otherPartyValue: '',
      otherPartyName: ''
    })
    this.parties = this.getPartiesFromElections().pipe(
      tap(parties => {
        parties.forEach(party => {
          this.form.addControl(`party.${party.id}`, new FormControl(''))
        })
        this.restoreState(this.form, parties)
      })
    ) as any
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  continue(
    opts: { replaceUrl?: boolean; saveForm?: boolean } = { replaceUrl: false, saveForm: false }
  ) {
    this.browserStorage.setItem('user-survey-shown', 'true')
    const { replaceUrl, saveForm } = opts
    if (saveForm) {
      this.saveState(this.form)
    }

    // tracking
    if (saveForm) {
      this.trackingService.trackEvent('Matching', 'saveQuestionsAboutYou')
    } else if (replaceUrl) {
      this.trackingService.trackEvent('Matching', 'autoSkipQuestionsAboutYou')
    } else {
      this.trackingService.trackEvent('Matching', 'skipQuestionsAboutYou')
    }

    this.router.navigate(['matching', 'results'], { replaceUrl })
  }

  reset(partyId) {
    this.resetControl(`party.${partyId}`)
  }

  resetControl(controlName: string) {
    const control = this.form.controls[controlName]
    if (control) {
      control.reset('')
    }
  }

  private getPartiesFromElections() {
    return this.apollo
      .query<GetElectionPartiesQuery>({
        query: GetElectionParties
      })
      .pipe(
        map(({ data }) =>
          data.elections[0].parties.filter(p => DISPLAYED_PARTY_IDS.indexOf(p.id) > -1)
        )
      )
  }

  private restoreState(form: FormGroup, parties) {
    const state = this.browserStorage.getItem(USER_SURVEY_KEY)
    if (!state) {
      return
    }

    try {
      const obj = JSON.parse(state)
      form.setValue(obj)
      // Mark everything as dirty
      form.markAsDirty()
      parties.forEach(p => {
        if (obj[`party.${p.id}`]) {
          form.controls[`party.${p.id}`].markAsDirty()
        }
      })
    } catch (e) {}
  }

  private saveState(form) {
    this.browserStorage.setItem(USER_SURVEY_KEY, JSON.stringify(form.value))
  }
}
