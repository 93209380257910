<div class="svi-profile">
  <svi-profile-photo class="photo" [photoUrl]="responder.photoUrl"></svi-profile-photo>
  <div class="content profile-header">
    <div class="name">{{ displayName }}</div>
    <div
      *ngIf="
        getValue(responder, 'website') ||
        getValue(responder, 'twitter') ||
        getValue(responder, 'facebook') ||
        getValue(responder, 'youtube')
      "
      class="social"
    >
      <span class="social-links-label d-none d-sm-inline" i18n>Web profiles:</span>
      <div class="social-links">
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'website') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fa fa-globe"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'twitter') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'facebook') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-facebook-square"></i>
        </a>
        <a
          rel="external"
          target="_blank"
          *ngIf="getValue(responder, 'youtube') as url"
          [href]="getTrustedUrl(url)"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="content profile-details">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="getValue(responder, 'statement')" class="svi-statement">
  <span class="profile-detail-label" i18n>Statement:</span>
  <div>
    <i>{{ getValue(responder, 'statement') }}</i>
  </div>
</div>

<svi-tab-group
  *ngIf="responder.answers.length > 0"
  class="tabs"
  (selectedTabChanged)="selectedTabChanged.emit($event)"
  [(selectedIndex)]="tabIndex"
>
  <!-- Sharing info -->
  <ng-container
    #sharingInfo="sviSharingInfo"
    sviSharingInfo
    title="Luxembourg National Election 2018"
    i18n-title
    description="Here is the smarspider of {{ displayName }}"
    i18n-description
    [imageFromElement]="sharingElement"
    [sharePath]="sharePath"
  ></ng-container>
  <svi-tab label="Smartspider" i18n-label>
    <div class="button-group">
      <button sviSharingTrigger [sharingInfo]="sharingInfo" class="button button-share">
        <i class="fa fa-share"></i>
        <ng-container i18n>Share smartspider</ng-container>
      </button>
    </div>
    <sv-smartspider
      #sharingElement="sviSharingElement"
      sviSharingElement
      [screenshotHeight]="650"
      class="smartspider"
      [smartspiders]="voter ? [responder.smartspider, voter.smartspider] : [responder.smartspider]"
    ></sv-smartspider>
    <div class="smartspider-legend">
      <svi-chart-legend-item
        *ngIf="voter"
        class="legend-item"
        label="My smartspider"
        i18n-label
      ></svi-chart-legend-item>
      <svi-chart-legend-item
        class="legend-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      ></svi-chart-legend-item>
    </div>
    <div class="more-info-placeholder"></div>
    <cdk-accordion>
      <svi-accordion-item
        title="More info about Smartspider"
        i18n-title
        [expanded]="infoTextExpanded"
      >
        <div class="info-panel">
          <p class="hint">
            <ng-container i18n
              >This is a graphical visualisation based on the questions. Each axis represents 2-4
              questions. Some questions have been assigned to multiple axes and some questions have
              not been assigned to any axes. You can find out which questions are assigned with
              which axis on the
            </ng-container>
            <a class="methodology-link" (click)="navigateToMethodology()" i18n>
                methodology page.
          </a>
          </p>
        </div>
      </svi-accordion-item>
    </cdk-accordion>
  </svi-tab>
  <svi-tab label="Questionnaire" i18n-label>
    <div class="answer-option-selection-header">
      <div class="official-answers-disclaimer" *ngIf="hasOfficialAnswers(responder)">
        <i class="fa fa-info-circle"></i>
        <ng-container *ngIf="isCandidate(responder)" i18n>
          Candidate answers are based on official party answers.
        </ng-container>
        <ng-container *ngIf="!isCandidate(responder)" i18n>
          This party's answers are based on party submissions and coding by smartvote Australia.
        </ng-container>
      </div>
      <span class="header-item" *ngIf="voter && voter.isMe">
        <i class="fa fa-user-circle"></i>&nbsp;
        <ng-container i18n>Me</ng-container>
      </span>
      <svi-chart-legend-item
        class="header-item"
        [color]="'#' + responderColor"
        [label]="displayName"
      ></svi-chart-legend-item>
    </div>

    <cdk-accordion multi="true">
      <svi-accordion-item
        *ngFor="let category of categories; first as isFirstCategory"
        [title]="category"
        [expanded]="isFirstCategory"
      >
        <svi-question-item
          class="svi-question-item"
          [class.active]="i % 2 === 0"
          *ngFor="let qItem of questionItems[category]; first as isFirst; let i = index"
          [text]="qItem.text"
          [comment]="qItem.comment"
          [index]="qItem.index"
        >
          <svi-answer-option-group
            *ngIf="qItem.type === 'StandardQuestion'"
            class="answer-option-group"
          >
            <svi-answer-option-item
              [selections]="qItem.answerSelections['100']"
              label="Definitely yes"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['75']"
              label="Rather yes"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['25']"
              label="Rather no"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['0']"
              label="Definitely no"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
          </svi-answer-option-group>
          <svi-answer-option-group
            *ngIf="qItem.type === 'Standard5Question'"
            class="answer-option-group"
          >
            <svi-answer-option-item
              [selections]="qItem.answerSelections['0']"
              label="Much less"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['25']"
              label="Less"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['50']"
              label="Same"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['75']"
              label="More"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
            <svi-answer-option-item
              [selections]="qItem.answerSelections['100']"
              label="Much more"
              i18n-label
              [showLabel]="isFirst"
              [color]="'#' + responderColor"
            ></svi-answer-option-item>
          </svi-answer-option-group>
        </svi-question-item>
      </svi-accordion-item>
    </cdk-accordion>
  </svi-tab>
</svi-tab-group>
