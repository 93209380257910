import { Component, Inject } from '@angular/core'
import { NavbarMenuOverlayRemote } from './navbar-menu-overlay-remote'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { BrowserStorage } from '../../../../core/tokens'

@Component({
  selector: 'svi-navbar-menu-overlay',
  templateUrl: './navbar-menu-overlay.component.html',
  styleUrls: ['./navbar-menu-overlay.component.scss']
})
export class NavbarMenuOverlayComponent {
  constructor(
    public remoteControl: NavbarMenuOverlayRemote,
    private _location: Location,
    private _router: Router,
    @Inject(BrowserStorage) private browserStorage: Storage
  ) {}

  // Close this overlay via it's remote control
  close() {
    this.remoteControl.close()
  }

  goToMatching() {
    const recommendationId = this.browserStorage.getItem('recommendationId')
    if (recommendationId) {
      this._router.navigate(['matching', 'results'], { queryParams: { rid: recommendationId } })
    } else {
      this._router.navigate(['matching'])
    }
    this.close()
  }
}
