<svi-navbar></svi-navbar>
<svi-page-title i18n>
  About us
</svi-page-title>
<svi-page-content>
  <section>
    <h3 i18n>Who we are</h3>
    <p i18n>smartwielen is a project of the University of Luxembourg in collaboration with the Zentrum fir politesch Bildung.
      The smartwielen website is an adapted version of smartvote, a so-called VAA (Voting Advice Application) that was first
      developed in Switzerland in 2003 and is operated by the politically neutral, non-profit organization Politools.
    </p>
  </section>
  <hr />
  <section class="institution">
    <div class="header">
      <div class="logo anu-spir"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>University of Luxembourg</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Professor Patrick Dumont </span>
            </li>
            <li>
              <span class="name">Professor Ian McAllister</span>
            </li>
            <li>
              <span class="name">Dr Jill Sheppard</span>
              <span class="function" i18n>Lecturer</span>
            </li>
            <li>
              <span class="name">Mark Kenny</span>
              <span class="function" i18n>Senior fellow</span>
            </li>
            <li>
              <span class="name">Dr Marija Taflaga </span>
              <span class="function" i18n>Lecturer</span>
            </li>
            <li>
              <span class="name">Dr Shawn Treier</span>
              <span class="function" i18n>Senior Lecturer</span>
            </li>
            <li>
              <span class="name">Feodor Snagovsky</span>
              <span class="function" i18n>PhD Student</span>
            </li>
            <li>
              <span class="name">Bermond Scoggins</span>
              <span class="function" i18n>PhD Student</span>
            </li>
            <li>
              <span class="name">Matt Bowes</span>
              <span class="function" i18n>Research Assistant</span>
            </li>
            <li>
              <span class="name">Emily Downie</span>
              <span class="function" i18n>Communications Administrator</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>
      The University of Luxembourg is a public research and higher education institution based in Luxembourg. The university consists
      of three faculties and three interdisciplinary centres.
    </p>
    <p i18n>
      The Faculty of Language and Literature, Humanities, Arts and Education (FLHASE) is specialised in research in the social
      sciences and humanities. It consists of three research units. The politcal scientists involved in the smartwielen project
      are associated with the political governance programme in the “Identités, Politiques, Sociétés, Espaces” (IPSE) research
      unit.
    </p>
    <div>
      <a href="https://www.anu.edu.au/" target="_blank" style="display: block">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>University of Luxembourg</ng-container>
      </a>
    </div>
  </section>
  <hr />
  <section class="institution">
    <div class="header">
      <div class="logo smartvote"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>smartvote/Politools</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Robin Bartlett Rissi</span>
              <span class="function" i18n>Project manager</span>
            </li>
            <li>
              <span class="name">Jan Fivaz</span>
              <span class="function" i18n>Founder</span>
            </li>
            <li>
              <span class="name">Daniel Schwarz</span>
              <span class="function" i18n>Founder</span>
            </li>
            <li>
              <span class="name">Albert Waaijenberg</span>
              <span class="function" i18n>Web design</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>
      smartvote is a Swiss Voting Advice Application (VAA) developed and run by Politools – an interdisciplinary scientific network
      that operates internet-based projects to promote civic education, political transparency as well as politicial analysis.
      It is located in Bern, Switzerland. Since 2003, smartvote has informed voters in over 200 elections throughout Switzerland
      and Europe.
    </p>
    <div>
      <a href="https://www.smartvote.org/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>smartvote</ng-container>
      </a>
    </div>
  </section>
  <hr />
  <section class="institution">
    <div class="header">
      <div class="logo soom-it"></div>
      <div class="details">
        <div class="title">
          <h4 i18n>soom-it</h4>
        </div>
        <div class="members">
          <ul>
            <li>
              <span class="name">Cédric Reginster</span>
            </li>
            <li>
              <span class="name">Johannes Lötscher</span>
            </li>
            <li>
              <span class="name">Matthias Flückiger</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p i18n>A technology company based in Bern, Switzerland, soom-it focuses on the conception, architecture and development
      of sophisticated custom-made software and web applications. As the main technological partner, soom-it supports the
      development of the smartvote platform since 2014.
    </p>
    <div>
      <a href="http://soom-it.ch/" target="_blank">
        <i class="fa fa-angle-right"></i>
        <ng-container i18n>soom-it</ng-container>
      </a>
    </div>
  </section>
</svi-page-content>
