/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-privacy.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../core/tokens";
import * as i7 from "../../shared/page-title/page-title.component.ngfactory";
import * as i8 from "../../shared/page-title/page-title.component";
import * as i9 from "../../shared/page-content/page-content.component.ngfactory";
import * as i10 from "../../shared/page-content/page-content.component";
import * as i11 from "./terms-and-privacy.page";
import * as i12 from "../../core/tracking.service";
var styles_TermsAndPrivacyPage = [i0.styles];
var RenderType_TermsAndPrivacyPage = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndPrivacyPage, data: {} });
export { RenderType_TermsAndPrivacyPage as RenderType_TermsAndPrivacyPage };
export function View_TermsAndPrivacyPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Location, i5.Router, i6.BrowserStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svi-page-title", [], null, null, null, i7.View_PageTitleComponent_0, i7.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i8.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Terms and privacy\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "svi-page-content", [], null, null, null, i9.View_PageContentComponent_0, i9.RenderType_PageContentComponent)), i1.ɵdid(6, 49152, null, 0, i10.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["smartvote Australia uses cookies and the local storage of your web browser to improve performance and usability. "])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The document below contains a detailed description of our privacy policy as well as the general terms of use for users. We strongly encourage everyone to carefully read this document."])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "a", [["href", "/assets/pdf/Privacy_policy_and_terms_of_use_for_voters.pdf"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.trackViewTerms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy policy and terms of use for users (PDF)"]))], null, null); }
export function View_TermsAndPrivacyPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-terms-and-privacy", [], null, null, null, View_TermsAndPrivacyPage_0, RenderType_TermsAndPrivacyPage)), i1.ɵdid(1, 4243456, null, 0, i11.TermsAndPrivacyPage, [i1.PLATFORM_ID, i12.TrackingService], null, null)], null, null); }
var TermsAndPrivacyPageNgFactory = i1.ɵccf("svi-terms-and-privacy", i11.TermsAndPrivacyPage, View_TermsAndPrivacyPage_Host_0, {}, {}, []);
export { TermsAndPrivacyPageNgFactory as TermsAndPrivacyPageNgFactory };
