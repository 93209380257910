import { Injectable } from '@angular/core'
import { Responder, isCandidate } from '@smartvote/common'

const PARTIES_WITH_OFFICIAL_ANSWERS = ['1', '2']

@Injectable()
export class OfficialAnswersService {
  hasOfficialAnswers(responder: Responder): boolean {
    if (isCandidate(responder)) {
      return (
        isCandidate(responder) && PARTIES_WITH_OFFICIAL_ANSWERS.indexOf(responder.party.id) > -1
      )
    } else {
      return PARTIES_WITH_OFFICIAL_ANSWERS.indexOf(responder.id) > -1
    }
  }
}
