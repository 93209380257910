export const environment = {
  production: true,
  portierUrl: 'https://aus-portier.smartvote.org/graphql',
  sharing: {
    twitterUsername: 'smartvoteAU',
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot'
  },
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 8
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<noreply@smartvote.org>',
  contactFormToAddress: 'info@smartvote.org'
}
