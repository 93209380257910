/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy-layover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./policy-layover.component";
import * as i4 from "../tokens";
var styles_PolicyLayoverComponent = [i0.styles];
var RenderType_PolicyLayoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyLayoverComponent, data: {} });
export { RenderType_PolicyLayoverComponent as RenderType_PolicyLayoverComponent };
function View_PolicyLayoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "language"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLanguage(_v.context.$implicit.code) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "separator"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.labelCode; _ck(_v, 2, 0, currVal_0); }); }
function View_PolicyLayoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "policy-layover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "policy-layover__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "languages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyLayoverComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy policy and terms of use"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "p", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "button button-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I agree\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.link; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.linkText; _ck(_v, 13, 0, currVal_3); }); }
export function View_PolicyLayoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyLayoverComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.accepted; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PolicyLayoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-policy-layover", [], null, null, null, View_PolicyLayoverComponent_0, RenderType_PolicyLayoverComponent)), i1.ɵdid(1, 4243456, null, 0, i3.PolicyLayoverComponent, [i2.Location, i1.PLATFORM_ID, i4.BrowserStorage], null, null)], null, null); }
var PolicyLayoverComponentNgFactory = i1.ɵccf("svi-policy-layover", i3.PolicyLayoverComponent, View_PolicyLayoverComponent_Host_0, { text: "text", linkText: "linkText", link: "link" }, { hasAccepted: "hasAccepted" }, []);
export { PolicyLayoverComponentNgFactory as PolicyLayoverComponentNgFactory };
