<svi-navbar></svi-navbar>
<svi-page-title i18n>
  Partners
</svi-page-title>
<svi-page-content>
  <section>
    <h3 i18n>Media partners</h3>
    <hr />
    <div class="partner">
      <a href="https://www.smh.com.au/" target="_blank" class="logo smh"></a>
      <div class="name">
        The Sydney Morning Herald<br />
        <i>Independent. Always.</i>
      </div>
    </div>
    <hr />
    <div class="partner">
      <a href="https://www.theage.com.au/" target="_blank" class="logo age"></a>
      <div class="name">
        The Age<br />
        <i>Independent. Always.</i>
      </div>
    </div>
  </section>
</svi-page-content>
