import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '../shared/shared.module'

import { DatabasePage } from './database.page'
import { DatabaseFilterGroupComponent } from './database-filter-group/database-filter-group.component'

const COMPONENTS = [DatabasePage, DatabaseFilterGroupComponent]

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DatabaseModule {}
