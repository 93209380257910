import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, switchMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Question, Answer } from '@smartvote/common';
import { AnswerService } from '../core/answer.service';
var query = require('graphql-tag/loader!./questionnaire.query.graphql');
var QuestionnairePage = /** @class */ (function () {
    function QuestionnairePage(route, router, apollo, answerService) {
        this.route = route;
        this.router = router;
        this.apollo = apollo;
        this.answerService = answerService;
    }
    QuestionnairePage.prototype.ngOnInit = function () {
        var _this = this;
        this.questions = this.route.queryParamMap.pipe(tap(function (params) {
            _this.questionIndex = parseInt(params.get('idx'), 10) || 0;
        }), switchMap(function () { return _this._getQuery(); }), map(function (_a) {
            var data = _a.data, loading = _a.loading;
            var questions = data.questionnaire.questions;
            _this.userAnswers = _this.answerService.getUserAnswersAsArray(questions);
            _this.hasAnswers = _this.userAnswers.some(function (a) { return a.value >= 0; });
            _this.progress = _this.questionIndex + 1 + "/" + questions.length;
            return questions;
        }));
    };
    QuestionnairePage.prototype.changeAnswer = function (answer) {
        this.hasAnswers = this.userAnswers.some(function (a) { return a.value >= 0; });
        this.answerService.saveAnswer(answer);
    };
    QuestionnairePage.prototype.navigate = function (direction) {
        var newQuestionIndex = this.questionIndex + parseInt(direction, 10);
        this.jumpToQuestion(newQuestionIndex);
    };
    QuestionnairePage.prototype.jumpToQuestion = function (newQuestionIndex) {
        if (this.userAnswers[this.questionIndex].value < -9) {
            this.answerService.saveAnswer(Object.assign(this.userAnswers[this.questionIndex], { value: -9 }));
        }
        this.router.navigate(['matching', 'questionnaire'], {
            queryParams: { idx: newQuestionIndex },
            replaceUrl: true
        });
    };
    QuestionnairePage.prototype.showResults = function () {
        this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } });
    };
    QuestionnairePage.prototype._getQuery = function () {
        return this.apollo.query({
            query: query
        });
    };
    return QuestionnairePage;
}());
export { QuestionnairePage };
