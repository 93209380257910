import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../shared/shared.module'

import { AboutUsPage } from './about-us/about-us.page'
import { ContactPage } from './contact/contact.page'
import { MethodologyPage } from './methodology/methodology.page'
import { PartnersPage } from './partners/partners.page'
import { TermsAndPrivacyPage } from './terms-and-privacy/terms-and-privacy.page'
import { FaqPage } from './faq/faq.page'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

const COMPONENTS = [
  AboutUsPage,
  ContactPage,
  MethodologyPage,
  PartnersPage,
  TermsAndPrivacyPage,
  FaqPage
]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class StaticPagesModule {}
