<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <select *ngIf="elections.length > 1" class="form-control" formControlName="election" autofocus>
      <option value="" i18n>Select Election</option>
      <option *ngFor="let election of elections" [value]="election.id">{{ election.name }}</option>
    </select>
    <div class="district-group" *ngIf="districtGroups.length">
      <select
        class="form-control"
        formControlName="districtGroup"
        (change)="trackSelectDistrictGroup($event.target.value)"
      >
        <option value="" i18n>Select State</option>
        <option *ngFor="let districtGroup of districtGroups" [value]="districtGroup.id">{{
          districtGroup.name
        }}</option>
      </select>
      <input
        class="form-control"
        formControlName="zipCode"
        placeholder="Enter your zip code (optional)"
        i18n-placeholder
      />
    </div>

    <select
      class="form-control"
      formControlName="district"
      (change)="trackSelectDistrict($event.target.value)"
      *ngIf="districts[0]"
    >
      <!-- Create one <option i18n *ngIf="..."> for every possible value of the type attribute.
        This is to select the correct term (territory/state/district/etc). Translations will be handeld via i18n. -->
      <option value="" i18n *ngIf="districts[0].type?.toLowerCase() === 'state'"
        >Select State</option
      >
      <option value="" i18n *ngIf="districts[0].type?.toLowerCase() !== 'state'"
        >Select District</option
      >
      <option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</option>
    </select>
    <span class="aec-hint" *ngIf="districts[0] && districtGroups[0]" i18n>
      Still can’t find your electorate? Go to the
      <a
        class="aec-hint aec-link"
        href="https://www.aec.gov.au/Electorates/division-finders.htm"
        target="_blank"
      >
        Australian Electoral Commission
      </a>
    </span>
  </form>
  <div *ngIf="state.district && !districtGroups.length" class="button-group">
    <button
      [disabled]="disabled"
      [ngClass]="{ active: state.responderType === 'Candidate' }"
      (click)="onResponderTypeChange('Candidate')"
      i18n
    >Show Candidates</button>
    <button
      [disabled]="disabled"
      [ngClass]="{ active: state.responderType === 'Party' }"
      (click)="onResponderTypeChange('Party')"
      i18n
    >Show Parties</button>
  </div>
</div>
