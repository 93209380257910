<div class="party info-item">
  {{ election.name }}
  <ng-container *ngIf="election"
    >| <ng-container>{{ candidate.party.name }}</ng-container
    >&nbsp;</ng-container
  >
  <ng-container *ngIf="isIncumbent(candidate)"
    >| <ng-container i18n>Incumbent</ng-container>&nbsp;</ng-container
  >
  <ng-container *ngIf="isElected(candidate)"
    >| <ng-container i18n>Elected</ng-container>&nbsp;</ng-container
  >
</div>
<!-- Create one <span i18n *ngIf="..."> for every possible value of the type attribute.
        This is to select the correct term (territory/state/district/etc). Translations will be handeld via i18n. -->
<div *ngIf="candidate.district" class="info-item">
  <span class="profile-detail-label" i18n *ngIf="candidate.district.type?.toLowerCase() === 'state'"
    >State</span
  >
  <span class="profile-detail-label" i18n *ngIf="candidate.district.type?.toLowerCase() !== 'state'"
    >District</span
  >
  : {{ candidate.district.name }}
  <span *ngIf="districtGroup">({{ districtGroup.name }})</span>
</div>
<div *ngIf="candidate.profession" class="info-item">
  <span class="profile-detail-label" i18n>Profession</span>: {{ candidate.profession }}
</div>
<div *ngIf="getValue(candidate, 'currentPoliticalPosition')" class="info-item">
  <span class="profile-detail-label" i18n>Current Political Position</span>:
  {{ getValue(candidate, 'currentPoliticalPosition') }}
</div>
<div *ngIf="candidate.gender" class="info-item">
  <span class="profile-detail-label" i18n>Gender</span><ng-container>:&nbsp;</ng-container>
  <span *ngIf="candidate.gender === 1" i18n>Female</span>
  <span *ngIf="candidate.gender === 2" i18n>Male</span>
  <span *ngIf="candidate.gender === 3" i18n>Other</span>
</div>
<div *ngIf="candidate.yearOfBirth" class="info-item">
  <span class="profile-detail-label" i18n>Year of birth</span>: {{ candidate.yearOfBirth }}
</div>
