<svi-navbar backLabel="Back to questionnaire" i18n-backLabel backRoute="/matching"></svi-navbar>
<svi-page-title i18n>
  Your Individualized Results
</svi-page-title>

<!-- Placeholder -->
<ng-template #placeholder>
  <svi-tab-group
    *ngIf="!loading"
    class="svi-matching-results"
    [(selectedIndex)]="tabIndex"
    [isPlaceholder]="true"
  >
    <svi-tab label="Ranking" i18n-label>
      <svi-list-item
        *ngFor="let item of [1, 2, 3, 4]; let i = index"
        [isPlaceholder]="true"
        [matchingValue]="0"
        [class.inactive]="true"
        [class.placeholder]="true"
      >
      </svi-list-item>
    </svi-tab>
    <svi-tab label="smartmap"> </svi-tab>
  </svi-tab-group>
</ng-template>

<ng-template #resultsActions let-sharingInfo="sharingInfo">
  <div class="actions">
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button d-none d-sm-inline-block"
      (click)="trackSharingTrigger()"
    >
      <i class="fa fa-share-alt"></i>
      <ng-container i18n>Share Results</ng-container>
    </button>
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button button-icon d-inline-block d-sm-none"
    >
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
</ng-template>

<svi-page-content>
  <svi-filter-group
    [disabled]="loading"
    [elections]="elections | async"
    [state]="filterGroupStateChanges | async"
    (stateChanged)="onFilterGroupChanged($event)"
  ></svi-filter-group>
</svi-page-content>

<svi-loading *ngIf="loading"></svi-loading>

<ng-container *ngIf="(recommendation | async) as recommendation; else placeholder">
  <ng-container *ngIf="!loading">
    <svi-tab-group
      class="svi-matching-results"
      (selectedTabChanged)="onTabChanged($event)"
      [(selectedIndex)]="tabIndex"
    >
      <svi-tab label="Ranking" i18n-label>
        <!-- Sharing Info, set meta
        tags only if this tab is active -->
        <ng-container
          #sharingInfo="sviSharingInfo"
          sviSharingInfo
          [setMetaTags]="tabIndex === 0"
          title="Luxembourg National Election 2018"
          i18n-title
          description="Here is my voting advice for the Luxembourg National Election 2018"
          i18n-description
          [imageFromElement]="sharingElement"
          [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="resultsActions; context: { sharingInfo: sharingInfo }"
        ></ng-container>
        <div #sharingElement="sviSharingElement" sviSharingElement>
          <svi-list-item
            *ngFor="let matching of recommendation.matchings; let i = index"
            [class.lastseat]="false"
            [class.inactive]="
              recommendation.options.responderType === 'Candidate' &&
              !(matching.matchingValue || matching.matchingValue === 0)
            "
            [class.has-official-answers]="hasOfficialAnswers(matching.responder)"
            [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
            [rank]="matching.description.includes('(No answers)') ? '' : i + 1"
            [title]="matching.title"
            [description]="matching.description"
            [matchingValue]="matching.matchingValue"
            (click)="onMatchingSelected(matching)"
          >
          </svi-list-item>
          <div *ngIf="someHaveOfficialPartyAnswers && isCandidateRecommendation" class="official-answers-disclaimer" i18n>
            Based on official party answers
          </div>
          <div *ngIf="someHaveOfficialPartyAnswers && !isCandidateRecommendation" class="official-answers-disclaimer" i18n>
            This party's answers are based on party submissions and coding by smartvote Australia.
          </div>
        </div>
      </svi-tab>
      <svi-tab label="smartmap">
        <!-- Sharing Info, set meta
        tags only if this tab is active -->
        <ng-container
          #sharingInfo2="sviSharingInfo"
          sviSharingInfo
          [setMetaTags]="tabIndex === 1"
          title="Luxembourg National Election 2018"
          i18n-title
          description="Here is my smartmap for the Luxembourg National Election 2018"
          i18n-description
          [imageFromElement]="sharingElement2"
          [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="resultsActions; context: { sharingInfo: sharingInfo2 }"
        ></ng-container>

        <div class="smartmap">
          <div>
            <label class="checkbox-container"
              ><ng-container i18n>Show my smartmap position</ng-container>
              <input [(ngModel)]="showMySmartmapPosition" type="checkbox" checked="checked" />
              <span class="checkmark"></span>
            </label>
          </div>

          <div *ngIf="(elections | async) as elections">
            <sv-smartmap
              #sharingElement2="sviSharingElement"
              [class.hide-me]="!showMySmartmapPosition"
              sviSharingElement
              [screenshotHeight]="560"
              [positions]="recommendation.positions"
              [xAxisLabels]="[elections[0].dimensions[0].pole1, elections[0].dimensions[0].pole2]"
              [yAxisLabels]="[elections[0].dimensions[1].pole2, elections[0].dimensions[1].pole1]"
              (onSelect)="onSmartmapItemSelect($event)"
            ></sv-smartmap>
          </div>
          <ul class="smartmap-legend">
            <li *ngIf="recommendation.voter && showMySmartmapPosition">
              <svi-chart-legend-item
                label="My position"
                i18n-label
                color="#000"
              ></svi-chart-legend-item>
            </li>
            <li *ngFor="let item of recommendation.smartmapLegendItems">
              <svi-chart-legend-item
                [label]="item.label"
                [color]="item.color"
              ></svi-chart-legend-item>
            </li>
          </ul>
          <div class="more-info-placeholder"></div>
          <cdk-accordion>
            <svi-accordion-item
              title="More info about Smartmap"
              i18n-title
              [expanded]="infoTextExpanded"
            >
              <div class="info-panel">
                <p class="hint">
                  <ng-container i18n
                    >This is a graphical visualisation based on the questions (or whatever the
                    method is). Each dimension represents ca. 20 questions. Some questions have been
                    assigned to both dimensions and some questions have not been assigned to any.
                    You can find out which questions are assigned with which axis on the
                  </ng-container>
                  <a class="methodology-link" (click)="navigateToMethodology()" i18n>
                      methodology page.
                  </a>
                </p>
              </div>
            </svi-accordion-item>
          </cdk-accordion>
        </div>
      </svi-tab>
    </svi-tab-group>
    <div class="svi-matching-result-summary">
      <div class="item">
        <!-- Create one <ng-container i18n *ngIf="..."> for every possible value of the type attribute.
        This is to select the correct term (territory/state/district/etc). Translations will be handeld via i18n. -->
        <ng-container i18n *ngIf="resultSummary.districtType?.toLowerCase() === 'state'"
          >State</ng-container
        >
        <ng-container i18n *ngIf="resultSummary.districtType?.toLowerCase() !== 'state'"
          >District</ng-container
        >
        : {{ resultSummary.district }}
      </div>
      <div class="item">
        <ng-container i18n>No. of seats</ng-container>: {{ resultSummary.nofSeats }}
      </div>
      <div class="item">
        <ng-container i18n>No. of candidates</ng-container>: {{ resultSummary.nofCandidates }}
      </div>
      <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
    </div>
  </ng-container>
</ng-container>

<ng-template #translations>
  <span id="no-information" i18n>No information</span>
  <span id="no-answers" i18n>No answers</span>
  <span id="my-position" i18n>My position</span>
</ng-template>
