<div class="svi-matching-list-item">

  <svi-profile-photo class="profile-photo" [photoUrl]="photoUrl"></svi-profile-photo>
  <div class="main">
    <div class="info">
      <div class="title truncate">
        <ng-container *ngIf="rank && rank !== ''">{{rank}}. </ng-container>
        {{title}}
      </div>
      <div *ngIf="description truncate" class="detail">
        {{description}}
      </div>
    </div>
    <div *ngIf="matchingValue || matchingValue === 0" class="matching-value">
      <svi-matching-value-bar class="bar" [value]="matchingValue"></svi-matching-value-bar>
      <div *ngIf="!isPlaceholder" class="number">{{matchingValue | number: '.1-1':'en'}}%</div>
    </div>
  </div>
  <i class="fa fa-chevron-right list-item-arrow"></i>
</div>