var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnChanges, EventEmitter, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
var QuestionnaireNavigationComponent = /** @class */ (function () {
    function QuestionnaireNavigationComponent(platformId) {
        this.platformId = platformId;
        this.navigate = new EventEmitter();
    }
    Object.defineProperty(QuestionnaireNavigationComponent.prototype, "questions", {
        set: function (questions) {
            this.shortQuestions = questions.map(function (question) {
                var splitQuestionText = question.text.split(' ');
                var shortQuestionText = '';
                if (splitQuestionText.length <= 10) {
                    shortQuestionText = question.text;
                }
                else {
                    shortQuestionText = splitQuestionText.slice(0, 10).join(' ') + '...';
                }
                return __assign({ textshort: shortQuestionText }, question);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionnaireNavigationComponent.prototype, "questionIndex", {
        set: function (questionIndex) {
            this._questionIndex = parseInt(questionIndex, 10);
        },
        enumerable: true,
        configurable: true
    });
    QuestionnaireNavigationComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId) && this.isMobileDevice) {
            // TODO: refactor to get rid of the hacky hardcoded 42
            window.scroll(0, this.container.nativeElement.offsetTop - 42);
        }
        if (this.shortQuestions && this.userAnswers) {
            this._hasAnswer = this.shortQuestions.map(function (question) {
                var currentUserAnswer = _this.userAnswers.filter(function (userAnswer) { return userAnswer.questionId === question.id; });
                return currentUserAnswer.length > 0 && currentUserAnswer[0].value > -9;
            });
        }
    };
    QuestionnaireNavigationComponent.prototype.onNavigate = function (newQuestionIndex) {
        this.navigate.emit(newQuestionIndex);
    };
    Object.defineProperty(QuestionnaireNavigationComponent.prototype, "isMobileDevice", {
        get: function () {
            return (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1);
        },
        enumerable: true,
        configurable: true
    });
    return QuestionnaireNavigationComponent;
}());
export { QuestionnaireNavigationComponent };
