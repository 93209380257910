var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TrackingService } from '../../core/tracking.service';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
var FilterGroupState = /** @class */ (function () {
    function FilterGroupState() {
        this.election = '';
        this.district = '';
        this.districtGroup = '';
        this.zipCode = '';
        this.responderType = 'Candidate';
    }
    return FilterGroupState;
}());
export { FilterGroupState };
export function stateIsValid(s) {
    return s.election && s.district;
}
export function stateIsEqual(a, b) {
    return (a.election === b.election &&
        a.district === b.district &&
        a.districtGroup === b.districtGroup &&
        a.responderType === b.responderType);
}
var FilterGroupComponent = /** @class */ (function () {
    function FilterGroupComponent(fb, trackingService, http) {
        var _this = this;
        this.trackingService = trackingService;
        this.http = http;
        this._disabled = false;
        /* Output `stateChanged` */
        this.stateChanged = new EventEmitter();
        this._state = new FilterGroupState();
        this.form = fb.group(this._state);
        this._sub = this.form.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (state) {
            _this._state = __assign({}, _this.state, state);
            if (_this.elections.length === 1) {
                // auto select single election
                _this._state.election = _this.elections[0].id;
            }
            if (_this.districtGroups.length === 1) {
                // auto select single districtGroup
                _this._state.districtGroup = _this.districtGroups[0].id;
            }
            if (_this.districts.length === 1) {
                // auto select single district
                _this._state.district = _this.districts[0].id;
            }
            _this.stateChanged.emit(_this._state);
        });
        this.http
            .get('/assets/json/zip-districts.json')
            .toPromise()
            .then(function (data) {
            _this.zipDistricts = data;
        });
    }
    Object.defineProperty(FilterGroupComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
            if (value) {
                this.form.disable({ emitEvent: false });
            }
            else {
                this.form.enable({ emitEvent: false });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "elections", {
        get: function () {
            return this._elections;
        },
        /* Input `elections` */
        set: function (value) {
            if (value) {
                this._elections = value;
            }
            else {
                this._elections = [];
            }
            this._setState(new FilterGroupState());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "state", {
        get: function () {
            return this._state;
        },
        /* Input `state` */
        set: function (value) {
            this._setState(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "districts", {
        get: function () {
            var _this = this;
            if (!this.election) {
                return [];
            }
            if (!this.election.districtGroups.length) {
                return this.election.districts;
            }
            if (this.state.zipCode) {
                return this.getDistrictsByZip(this.state.zipCode, this.election);
            }
            return this.election.districts.filter(function (d) {
                return d.groupId === _this.state.districtGroup;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "districtGroups", {
        get: function () {
            if (!this.election) {
                return [];
            }
            return this.election.districtGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "election", {
        get: function () {
            var _this = this;
            if (!this._state.election) {
                return;
            }
            return this.elections.find(function (e) { return e.id === _this.state.election; });
        },
        enumerable: true,
        configurable: true
    });
    FilterGroupComponent.prototype.ngOnDestroy = function () {
        this._sub.unsubscribe();
    };
    FilterGroupComponent.prototype.onResponderTypeChange = function (responderType) {
        this.trackingService.trackEvent('Matching', 'selectResponderType', responderType);
        this.form.patchValue({ responderType: responderType });
    };
    FilterGroupComponent.prototype.trackSelectDistrict = function (districtId) {
        this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId);
    };
    FilterGroupComponent.prototype.trackSelectDistrictGroup = function (districtGroupId) {
        this.trackingService.trackEvent('Matching', 'selectDistrictGroup', 'DistrictGroup' + districtGroupId);
    };
    FilterGroupComponent.prototype.getDistrictsByZip = function (zip, election) {
        var districts = this.zipDistricts.filter(function (zd) { return zd.zip_code === zip; }).map(function (zd) {
            return election.districts.find(function (d) { return d.id === zd.district_id; });
        });
        if (districts.length) {
            // set districtGroup
            this.form.controls.districtGroup.patchValue(districts[0].groupId, {
                emitEvent: false
            });
        }
        return districts;
    };
    FilterGroupComponent.prototype._setState = function (state) {
        if (state === void 0) { state = new FilterGroupState(); }
        this._state = state;
        if (this.elections.length > 1) {
            this.form.controls.election.enable({ emitEvent: false });
        }
        else {
            this.form.controls.election.disable({ emitEvent: false });
        }
        if (this.districtGroups.length > 1) {
            this.form.controls.districtGroup.enable({ emitEvent: false });
        }
        else {
            this.form.controls.districtGroup.disable({ emitEvent: false });
        }
        if (this.districts.length > 1) {
            this.form.controls.district.enable({ emitEvent: false });
        }
        else {
            this.form.controls.district.disable({ emitEvent: false });
        }
        this.form.patchValue(this._state, { emitEvent: false });
    };
    return FilterGroupComponent;
}());
export { FilterGroupComponent };
