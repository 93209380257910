<svi-navbar backLabel="Back to questionnaire" i18n-backLabel></svi-navbar>
<svi-page-title i18n>
  Questions About You
</svi-page-title>
<svi-page-content>
  <div class="grid grid-reverse grid-align-top">
    <div class="cell text-right">
      <button
        class="button button-large icon-right"
        (click)="continue({ saveForm: false })"
      >
        <ng-container i18n>Continue directly to your results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="cell">
      <p class="hint ">
        <b i18n>Please note</b>:
        <ng-container i18n
          >your answers will be treated anonymously and will not affect your results</ng-container
        >
      </p>
    </div>
  </div>

  <form class="svi-questions-about-you-form" [formGroup]="form">
    <div class="section">
      <h3 i18n>Biographical information</h3>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-home"></i>
          <input
            class="form-control"
            type="text"
            placeholder="Postal code"
            i18n-placeholder
            formControlName="zip"
          />
        </div>
        <div class="field cell">
          <div class="icon-group">
            <i class="fa fa-male"></i>
            <i class="fa fa-female"></i>
          </div>
          <select class="form-control" formControlName="gender">
            <option value="" i18n>Gender</option>
            <option value="1" i18n>Female</option>
            <option value="2" i18n>Male</option>
            <option value="3" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-birthday-cake"></i>
          <input
            class="form-control"
            type="text"
            placeholder="Year of birth"
            i18n-placeholder
            formControlName="yearOfBirth"
          />
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-university"></i>
          <select class="form-control" formControlName="education">
            <option value="" i18n>Education</option>
            <option value="Primary education" i18n>Primary education</option>
            <option value="Upper secondary education" i18n>Upper secondary education</option>
            <option value="Post-secondary non-tertiary education" i18n
              >Post-secondary non-tertiary education</option
            >
            <option value="Short-cycle tertiary education (bac +2)" i18n
              >Short-cycle tertiary education (bac +2)</option
            >
            <option value="Bachelor’s or equivalent level (Bac+3, Licence)" i18n
              >Bachelor’s or equivalent level (Bac+3, Licence)</option
            >
            <option value="Master’s or equivalent level" i18n>Master’s or equivalent level</option>
            <option value="Doctoral or equivalent level" i18n>Doctoral or equivalent level</option>
            <option value="other" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-flag"></i>
          <select class="form-control" formControlName="enrolledToVote">
            <option value="" i18n>Enrolled to vote</option>
            <option value="Yes" i18n>Yes</option>
            <option value="No" i18n>No</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-language"></i>
          <select class="form-control" formControlName="otherLanguage">
            <option value="" i18n>Other language spoken at home</option>
            <option value="EnglischOnly" i18n>No, English only</option>
            <option value="Mandarin" i18n>Yes, Mandarin</option>
            <option value="Italian" i18n>Yes, Italian</option>
            <option value="Arabic" i18n>Yes, Arabic </option>
            <option value="Cantonese" i18n>Yes, Cantonese</option>
            <option value="Greek" i18n>Yes, Greek</option>
            <option value="Vietnamese" i18n>Yes, Vietnamese</option>
            <option value="other" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-briefcase"></i>
          <select class="form-control" formControlName="profession">
            <option value="" i18n>Professional activity</option>
            <option value="private sector" i18n>private sector</option>
            <option value="public sector" i18n>public sector</option>
            <option value="self-employed" i18n>self-employed</option>
            <option value="student" i18n>student</option>
            <option value="unemployed" i18n>unemployed</option>
            <option value="housewife or househusband" i18n>housewife or househusband</option>
            <option value="retired" i18n>retired</option>
            <option value="other" i18n>Other</option>
            <option value="na" i18n>NA</option>
          </select>
        </div>
      </div>
    </div>

    <div class="section">
      <h3 i18n>Party preference</h3>
      <p class="hint">
        <ng-container i18n
          >How likely is it that you vote for a candidate from the following parties?</ng-container
        >
        <br />
        <ng-container i18n
          >'0' means "not at all likely " and '10' means "very likely”.</ng-container
        >
      </p>
      <div class="grid grid-gutter" *ngFor="let party of (parties | async)">
        <div
          class="cell field party-pref"
          [class.touched]="!form.controls['party.' + party.id].pristine"
        >
          <label>
            {{ party.name }}:
            <span class="party-rating">{{ form.controls['party.' + party.id].value }}</span>
            <br />
            <span
              class="link"
              [class.invisible]="form.controls['party.' + party.id].pristine"
              (click)="reset(party.id)"
              i18n
              >Reset</span
            >
          </label>
          <input type="range" min="0" max="10" step="1" formControlName="party.{{ party.id }}" />
        </div>
      </div>
      <div class="grid grid-gutter">
        <div
          class="cell field party-pref"
          [class.touched]="!form.controls['otherPartyValue'].pristine"
        >
          <label>
            <ng-container i18n>Other party</ng-container>:
            <span class="party-rating">{{ form.controls['otherPartyValue'].value }}</span>
            <br />
            <span
              class="link"
              [class.invisible]="form.controls['otherPartyValue'].pristine"
              (click)="resetControl('otherPartyValue')"
              i18n
              >Reset</span
            >
          </label>
          <input type="range" min="0" max="10" step="1" formControlName="otherPartyValue" />
        </div>
      </div>
      <div class="grid grid-gutter" *ngIf="!form.controls['otherPartyValue'].pristine">
        <div class="cell field">
          <p class="other-party hint" i18n>Please specify other party:</p>
        </div>
        <div class="cell field party-pref">
          <input
            class="form-control"
            type="text"
            placeholder="Other party name"
            i18n-placeholder
            formControlName="otherPartyName"
          />
        </div>
      </div>
    </div>
  </form>

  <div class="grid grid-gutter grid-align-bottom section">
    <div class="cell">
      <h3 i18n>Thank you for participating!</h3>
    </div>
    <div class="cell text-right">
      <button
        class="button button-primary button-large icon-right"
        (click)="continue({ saveForm: true })"
      >
        <ng-container i18n>See results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
</svi-page-content>
